var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('b-row',[_c('b-col',[_c('h1',[_vm._v(_vm._s(_vm.$t("front.header")))]),_c('p',[_vm._v(_vm._s(_vm.$t("front.description")))])])],1),_c('b-row',[_c('b-col',{attrs:{"col":"","md":"12","lg":"12"}},[_c('h2',{staticClass:"bg-sal-orange"},[_vm._v(_vm._s(_vm.$tc("event.event", 2)))]),_c('EventList',{attrs:{"limit":8,"limited":true}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"col":"","md":"12","lg":"12"}},[_c('h2',{staticClass:"bg-sal-orange"},[_vm._v(_vm._s(_vm.$t("sjal.ranking")))])])],1),_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6","lg":"4"}},[_c('h3',{staticClass:"bg-sal-orange"},[_vm._v(" "+_vm._s(_vm.$t("sjal.recurve"))+" ")]),_c('b-link',{attrs:{"to":{
              name: 'statistics-ranking',
              query: { division: 'y', rolling: true }
            }}},[_vm._v(_vm._s(_vm.$t("sjal.full_ranking"))+" Y")]),_c('StatisticsRanking',{attrs:{"division":"y","rolling":true,"limit":5,"limited":true}}),_c('b-link',{attrs:{"to":{
              name: 'statistics-ranking',
              query: { division: 'n', rolling: true }
            }}},[_vm._v(_vm._s(_vm.$t("sjal.full_ranking"))+" N")]),_c('StatisticsRanking',{attrs:{"division":"n","rolling":true,"limit":5,"limited":true}})],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"4"}},[_c('h3',{staticClass:"bg-sal-orange"},[_vm._v(" "+_vm._s(_vm.$t("sjal.compound"))+" ")]),_c('b-link',{attrs:{"to":{
              name: 'statistics-ranking',
              query: { division: 'yt', rolling: true }
            }}},[_vm._v(_vm._s(_vm.$t("sjal.full_ranking"))+" YT")]),_c('StatisticsRanking',{attrs:{"division":"yt","rolling":true,"limit":5,"limited":true}}),_c('b-link',{attrs:{"to":{
              name: 'statistics-ranking',
              query: { division: 'nt', rolling: true }
            }}},[_vm._v(_vm._s(_vm.$t("sjal.full_ranking"))+" NT")]),_c('StatisticsRanking',{attrs:{"division":"nt","rolling":true,"limit":5,"limited":true}})],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"4"}},[_c('h3',{staticClass:"bg-sal-orange"},[_vm._v(" "+_vm._s(_vm.$t("sjal.barebow"))+" ")]),_c('b-link',{attrs:{"to":{
              name: 'statistics-ranking',
              query: { division: 'yv', rolling: true }
            }}},[_vm._v(_vm._s(_vm.$t("sjal.full_ranking"))+" YV")]),_c('StatisticsRanking',{attrs:{"division":"yv","rolling":true,"limit":5,"limited":true}}),_c('b-link',{attrs:{"to":{
              name: 'statistics-ranking',
              query: { division: 'nv', rolling: true }
            }}},[_vm._v(_vm._s(_vm.$t("sjal.full_ranking"))+" NV")]),_c('StatisticsRanking',{attrs:{"division":"nv","rolling":true,"limit":5,"limited":true}})],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"4"}},[_c('h3',{staticClass:"bg-sal-orange"},[_vm._v(" "+_vm._s(_vm.$t("sjal.longbow"))+" ")]),_c('b-link',{attrs:{"to":{
              name: 'statistics-ranking',
              query: { division: 'ylb', rolling: true }
            }}},[_vm._v(_vm._s(_vm.$t("sjal.full_ranking"))+" YLB")]),_c('StatisticsRanking',{attrs:{"division":"ylb","rolling":true,"limit":5,"limited":true}}),_c('b-link',{attrs:{"to":{
              name: 'statistics-ranking',
              query: { division: 'nlb', rolling: true }
            }}},[_vm._v(_vm._s(_vm.$t("sjal.full_ranking"))+" NLB")]),_c('StatisticsRanking',{attrs:{"division":"nlb","rolling":true,"limit":5,"limited":true}})],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"4"}},[_c('h3',{staticClass:"bg-sal-orange"},[_vm._v(" "+_vm._s(_vm.$t("sjal.traditional"))+" ")]),_c('b-link',{attrs:{"to":{
              name: 'statistics-ranking',
              query: { division: 'ytr', rolling: true }
            }}},[_vm._v(_vm._s(_vm.$t("sjal.full_ranking"))+" YTR")]),_c('StatisticsRanking',{attrs:{"division":"ytr","rolling":true,"limit":5,"limited":true}}),_c('b-link',{attrs:{"to":{
              name: 'statistics-ranking',
              query: { division: 'ntr', rolling: true }
            }}},[_vm._v(_vm._s(_vm.$t("sjal.full_ranking"))+" NTR")]),_c('StatisticsRanking',{attrs:{"division":"ntr","rolling":true,"limit":5,"limited":true}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }