var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',[_c('b-col',[_c('h1',[_vm._v(_vm._s(_vm.athlete.first_name)+" "+_vm._s(_vm.athlete.last_name))])])],1),_c('b-row',[_c('b-col',[_c('h2',{staticClass:"bg-sal-orange"},[_vm._v(_vm._s(_vm.$t("athlete.info")))])])],1),_c('b-row',[_c('b-col',[(_vm.errors.main)?_c('b-alert',{attrs:{"variant":"danger","show":""}},[_c('ul',_vm._l((_vm.errors.main),function(e){return _c('li',{key:e},[_vm._v(_vm._s(e))])}),0)]):_vm._e()],1)],1),(_vm.loadingAthlete)?_c('b-row',[_c('b-col',[_c('b-spinner',{attrs:{"label":"Loading..."}})],1)],1):_c('b-row',[_c('b-col',{attrs:{"cols":"6","md":"3"}},[_c('dl',[_c('dt',[_vm._v(_vm._s(_vm.$t("first_name")))]),(_vm.athlete.first_name)?_c('dd',[_vm._v(_vm._s(_vm.athlete.first_name))]):_vm._e()])]),_c('b-col',{attrs:{"cols":"6","md":"3"}},[_c('dl',[_c('dt',[_vm._v(_vm._s(_vm.$t("last_name")))]),(_vm.athlete.last_name)?_c('dd',[_vm._v(_vm._s(_vm.athlete.last_name))]):_vm._e()])]),_c('b-col',{attrs:{"cols":"6","md":"3"}},[_c('dl',[_c('dt',[_vm._v(_vm._s(_vm.$t("athlete.sport_id")))]),(_vm.athlete.sport_id)?_c('dd',[_vm._v(_vm._s(_vm.athlete.sport_id))]):_vm._e()])]),_c('b-col',{attrs:{"cols":"6","md":"3"}},[_c('dl',[_c('dt',[_vm._v(_vm._s(_vm.$t("athlete.club")))]),(_vm.athlete.organization_info)?_c('dd',[_vm._v(" "+_vm._s(_vm.athlete.organization_info.name)+" ")]):_vm._e()])]),(_vm.$store.state.editMode && _vm.$store.state.user.is_staff && _vm.athlete)?_c('b-col',{attrs:{"cols":"6","md":"3"}},[_c('dl',[_c('dt',[_vm._v(_vm._s(_vm.$t("admin")))]),_c('dd',[_c('b-button',{staticClass:"btn-orange btn-sm",attrs:{"to":{
              name: 'admin',
              params: {
                model_name: 'athlete',
                object_id: _vm.athlete.id
              }
            },"variant":"light"}},[_vm._v(_vm._s(_vm.$t("admin"))+" ")])],1)])]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }